#app {
	height: 100%;
}

html,
body {
	position: relative;
	height: 100%;
}

body {
	background: #eee;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #000;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
	overscroll-behavior: none;
}

.container {
	min-width: 70px;
	width: max-content;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-width: 1px;
	border-color: #003098;
	border-radius: 8px;
	padding-left: 2px;
	padding-right: 2px;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	/* font-size: 18px; */
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.swiper-pagination {
	text-align: center !important; /* Align bullets to the center */
	position: fixed !important;
	margin-top: 7.9vh;
	background-color: #fff;
	border-bottom: #e5e5e5 2px solid;
	/* display: flex; */
	justify-content: space-between !important;
	flex-wrap: wrap;
	/* height:18vh; */
	overflow-y: scroll;
	padding-left: 15px; /* Add left padding for alignment */
}

.swiper-pagination > .swiper-pagination-bullet {
	min-width: 64px;
	width: max-content;
	height: 40px;
	text-align: center;
	justify-items: center;
	align-content: center;
	font-size: 16px;
	font-weight: 600;
	color: #003098;
	background-color: transparent;
	opacity: 1;
	margin-top: 2px !important;
	max-height: 120px;
	margin-bottom: 2px !important;
	margin-left: auto; /* Align bullet to the center */
	margin-right: auto; /* Align bullet to the center */
}

.swiper-pagination > .swiper-pagination-bullet-active > .container {
	color: #fff;
	background-color: #003098;
}

.swiper-pagination .swiper-pagination-line2,
.swiper-pagination .swiper-pagination-line3 {
	justify-content: flex-start;
}

.swiper-pagination .swiper-pagination-line2,
.swiper-pagination .swiper-pagination-line3 .swiper-pagination-bullet {
	margin-left: 0; /* Remove left margin for alignment */
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: auto;
	top: 0;
}

.align-left {
	display: flex;
	/* height:18vh; */
	/* text-align: left !important; */
	justify-content: flex-start !important; /* 左对齐 */
}

.margin-top-eighteen {
	height: 18vh;
}

.margin-top-ten {
	height: 10vh;
}
